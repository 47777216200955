// DEV
// export const API_URL = 'http://localhost/tony-rubber-api';

// PROD
//export const API_URL = 'http://www.tonyrubber.com';

// const config = require('config');
// import config from 'config';

let _API_URL = "http://localhost/tony-rubber-api";
if (process.env.NODE_ENV === "production") {
  _API_URL = "";
}

export const API_URL = _API_URL;
