export const containerSize = {
  // xs: { span: 24, offset: 0 },
  // sm: { span: 24, offset: 0 },
  md: { span: 24, offset: 0 },
  lg: { span: 20, offset: 2 },
  xl: { span: 16, offset: 4 },
};

export const formSize = {
  sm: { span: 24 },
  md: { span: 12 },
  // lg: { span: 12 },
  // xl: { span: 12 }
};

export const criteriaSize = {
  // labelCol: {
  //   span: 5
  // },
  // wrapperCol: {
  //   span: 19
  // }
};
