export const APP_VERSION = "1.0.16";
// export const localDataKey = "6XF7JGWB4uxwYjKvsPBsGXLX";
export const localDataKey = "7XF7JGWB4uxwY7KvsPBsGXLX";

export const bill_list_issue_date = "bill_list_issue_date";
export const bill_list_page = "bill_list_page";
export const debt_bill_list_issue_date = "debt_bill_list_issue_date";
export const report001_issue_date = "report001_issue_date";
export const report001_page = "report001_page";
export const report002_issue_date = "report002_issue_date";
export const report003_issue_date_from = "report003_issue_date_from";
export const report003_issue_date_to = "report003_issue_date_to";
export const report004_issue_date_from = "report004_issue_date_from";
export const report004_issue_date_to = "report004_issue_date_to";
export const report004_year_from = "report004_year_from";
export const report004_year_to = "report004_year_to";
export const report004_year = "report004_year";
export const report004_page = "report004_page";
export const report005_report_date = "report005_report_date";
export const report006_issue_date_from = "report006_issue_date_from";
export const report006_issue_date_to = "report006_issue_date_to";
export const report007_issue_date = "report007_issue_date";
export const report008_issue_date_from = "report008_issue_date_from";
export const report008_issue_date_to = "report008_issue_date_to";

export const MODE_NEW = 0;
export const MODE_VIEW = 1;
export const MODE_EDIT = 2;

export const menus = [
  {
    key: "bill",
    title: "รายการบิล",
    children: [
      { key: "bill-view", title: "ค้นหา และ ดูข้อมูล" }, //done
      { key: "bill-create", title: "สร้างบิล" }, //done
      { key: "bill-edit", title: "แก้ไขบิล" }, //done
      { key: "bill-edit-issue-date", title: "แก้ไขวันที่บันทึกบิล" },
      { key: "bill-delete", title: "ลบบิล" }, //done
      { key: "bill-print-bill", title: "พิมพ์บิล" }, //done
    ],
  },
  {
    key: "debt",
    title: "ระบบลูกหนี้",
    children: [
      { key: "debt-view", title: "ค้นหา และ ดูข้อมูล" },
      { key: "debt-increase", title: "เพิ่มหนี้" }, //done
      { key: "debt-decrease", title: "ชำระหนี้" }, //done
      { key: "debt-edit", title: "แก้ไขบิล" }, //done
      { key: "debt-delete", title: "ลบบิล" }, //done
      { key: "debt-print-info", title: "พิมพ์ข้อมูล" }, //done
      { key: "debt-print-bill", title: "พิมพ์บิล" }, //done
    ],
  },
  {
    key: "report",
    title: "รายงานสรุป",
    children: [
      {
        key: "report-1",
        title: "รายงานสรุปยอดซื้อตามลำดับบิล",
        children: [
          { key: "report-1-view", title: "ดูรายงาน" },
          { key: "report-1-print", title: "พิมพ์รายงาน" },
        ],
      },
      {
        key: "report-2",
        title: "รายงานสรุปยอดซื้อตามประเภทยาง",
        children: [
          { key: "report-2-view", title: "ดูรายงาน" },
          { key: "report-2-print", title: "พิมพ์รายงาน" },
        ],
      },
      {
        key: "report-3",
        title: "รายงานสรุปยอดซื้อรวม",
        children: [
          { key: "report-3-view", title: "ดูรายงาน" },
          { key: "report-3-print", title: "พิมพ์รายงาน" },
        ],
      },
      {
        key: "report-4",
        title: "รายงานสรุปยอดซื้อของสมาชิกกลุ่มวิสาหกิจชุมชน",
        children: [
          { key: "report-4-view", title: "ดูรายงาน" },
          { key: "report-4-print", title: "พิมพ์รายงาน" },
          { key: "report-4-print-info", title: "พิมพ์ข้อมูลยอดซื้อซื้อสมาชิก" },
          {
            key: "report-4-print-dividend",
            title: "พิมพ์ข้อมูลปันผลประจำปีของสมาชิก",
          },
        ],
      },
      {
        key: "report-5",
        title: "รายงานสรุปยอดซื้อของสมาชิกโทนี่ยางพารา",
        children: [
          { key: "report-5-view", title: "ดูรายงาน" },
          { key: "report-5-print", title: "พิมพ์รายงาน" },
          { key: "report-5-print-info", title: "พิมพ์ข้อมูลยอดซื้อซื้อสมาชิก" },
          {
            key: "report-5-print-dividend",
            title: "พิมพ์ข้อมูลปันผลประจำปีของสมาชิก",
          },
        ],
      },
      {
        key: "report-6",
        title: "รายงานสรุปยอดซื้อประจำวัน",
        children: [
          { key: "report-6-view", title: "ดูรายงาน" },
          { key: "report-6-save", title: "บันทึกรายงาน" },
          { key: "report-6-print", title: "พิมพ์รายงาน" },
        ],
      },
      {
        key: "report-7",
        title: "รายงานสรุปยอดขายอุปกรณ์",
        children: [
          { key: "report-7-view", title: "ดูรายงาน" },
          { key: "report-7-print", title: "พิมพ์รายงาน" },
        ],
      },
      {
        key: "report-8",
        title: "รายงานสรุปยอดขายน้ำกรดประจำวัน",
        children: [
          { key: "report-8-view", title: "ดูรายงาน" },
          { key: "report-8-save", title: "บันทึกรายงาน" },
        ],
      },
      {
        key: "report-9",
        title: "รายงานสรุปยอดคืนขวด",
        children: [
          { key: "report-9-view", title: "ดูรายงาน" },
          { key: "report-9-print", title: "พิมพ์รายงาน" },
        ],
      },
    ],
  },
  {
    key: "member1",
    title: "ระบบสมาชิกกลุ่มวิสาหกิจชุมชน",
    children: [
      { key: "member1-view", title: "ค้นหา และ ดูข้อมูล" },
      { key: "member1-create", title: "เพิ่มสมาชิก" }, // done
      { key: "member1-edit", title: "แก้ไขสมาชิก" }, // done
      { key: "member1-leave", title: "ออกจากสมาชิก" }, // done
      // , { key: "member1-delete", title: "ออกจากสมาชิก" }
      { key: "member1-print", title: "พิมพ์ข้อมูล" }, // done
      { key: "member1-print-card", title: "พิมพ์บัตร" }, // done
    ],
  },
  {
    key: "member2",
    title: "ระบบสมาชิกโทนี่ยางพารา",
    children: [
      { key: "member2-view", title: "ค้นหา และ ดูข้อมูล" },
      { key: "member2-create", title: "เพิ่มสมาชิก" }, // done
      { key: "member2-edit", title: "แก้ไขสมาชิก" }, // done
      // , { key: "member2-delete", title: "ลบสมาชิก" }
      // , { key: "member2-print", title: "พิมพ์ข้อมูล" }
    ],
  },
  {
    key: "member3",
    title: "เงินปันผลประจำปี",
    children: [
      { key: "member3-view", title: "ค้นหา และ ดูข้อมูล" },
      { key: "member3-upload", title: "อัพโหลดเงินปันผลประจำปี" },
    ],
  },
  {
    key: "user",
    title: "พนักงาน",
    children: [
      { key: "user-view", title: "ค้นหา และ ดูข้อมูล" },
      { key: "user-history", title: "ดูประวัติการเข้าใช้งาน" }, // done
      { key: "user-create", title: "เพิ่มผู้ใช้" }, // done
      { key: "user-delete", title: "ลบผู้ใช้" }, // done
      { key: "user-permission", title: "แก้ไขสิทธิ์การเข้าถึงสาขา" }, // done
      { key: "user-reset-password", title: "รีเซ็ตรหัสผ่านผู้ใช้" }, // done
    ],
  },
  {
    key: "setting",
    title: "ตั้งค่า",
    children: [
      { key: "setting-view", title: "ดูข้อมูลตั้งค่า" },
      { key: "setting-edit-general", title: "แก้ไข ตั้งค่า ทั่วไป" }, //done
      { key: "setting-edit-sale", title: "แก้ไข ตั้งค่า การขาย" }, //done
      { key: "setting-edit-name-title", title: "แก้ไข ตั้งค่า คำนำหน้า" }, //done
      { key: "setting-edit-bank", title: "แก้ไข ตั้งค่า ธนาคาร" }, //done
      { key: "setting-edit-product-cat", title: "แก้ไข ตั้งค่า ประเภทยาง" }, //done
      { key: "setting-edit-branch", title: "แก้ไข ตั้งค่า สาขา" }, //done
      { key: "setting-edit-equipment", title: "แก้ไข ตั้งค่า อุปกรณ์" }, //done
    ],
  },
];
