import { ConfigProvider, Layout } from "antd";
import thTH from "antd/es/locale/th_TH";
import moment from "moment";
import React, { Suspense } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import MainHeader from "./shared/components/Navigation/MainHeader";
import RegisterPDFFont from "./shared/components/PDFRenderer/RegisterPDFFont";
import { AppDataContext } from "./shared/context/app-data-context";
import { AuthContext } from "./shared/context/auth-context";
import { useAppData } from "./shared/hooks/app-data-hook";
import { useAuth } from "./shared/hooks/auth-hook";


import "./css/App.css";
import "./css/Custom.css";
import "./css/Header.css";
import "./css/Table.css";
import "react-calendar/dist/Calendar.css";
import { APP_VERSION } from "./shared/utils/constant";

const BillDetail = React.lazy(() => import("./bills/pages/BillDetail"));
const BillList = React.lazy(() => import("./bills/pages/BillList"));
const OfflineBillList = React.lazy(() => import("./bills/pages/OfflineBillList"));
const DebtListByBill = React.lazy(() => import("./debts/pages/DebtListByBill"));
const DebtListByMember = React.lazy(() => import("./debts/pages/DebtListByMember"));
const Home = React.lazy(() => import("./home/pages/Home"));
const MemberDetail = React.lazy(() => import("./members/pages/MemberDetail"));
const MemberDividend = React.lazy(() => import("./members/pages/MemberDividend"));
const MemberList = React.lazy(() => import("./members/pages/MemberList"));
const MemberTonyDetail = React.lazy(() => import("./members/pages/MemberTonyDetail"));
const MemberTonyList = React.lazy(() => import("./members/pages/MemberTonyList"));
const PrintListMembers = React.lazy(() => import("./members/pages/PrintListMembers"));
const ChangePassword = React.lazy(() => import("./profile/pages/ChangePassword"));
const Report001 = React.lazy(() => import("./reports/pages/Report001"));
const Report002 = React.lazy(() => import("./reports/pages/Report002"));
const Report003 = React.lazy(() => import("./reports/pages/Report003"));
const Report004Detail = React.lazy(() => import("./reports/pages/Report004Detail"));
const Report004List = React.lazy(() => import("./reports/pages/Report004List"));
const Report005 = React.lazy(() => import("./reports/pages/Report005"));
const Report006 = React.lazy(() => import("./reports/pages/Report006"));
const Report007 = React.lazy(() => import("./reports/pages/Report007"));
const Settings = React.lazy(() => import("./settings/pages/Settings"));
const ViewBank = React.lazy(() => import("./settings/pages/ViewBank"));
const ViewBranch = React.lazy(() => import("./settings/pages/ViewBranch"));
const ViewEquipment = React.lazy(() => import("./settings/pages/ViewEquipment"));
const ViewNameTitle = React.lazy(() => import("./settings/pages/ViewNameTitle"));
const ViewProductCat = React.lazy(() => import("./settings/pages/ViewProductCat"));
const Report008 = React.lazy(() => import("./reports/pages/Report008"));
const EditUser = React.lazy(() => import("./users/pages/EditUser"));
const Login = React.lazy(() => import("./users/pages/Login"));
const NewUser = React.lazy(() => import("./users/pages/NewUser"));
const Users = React.lazy(() => import("./users/pages/Users"));

RegisterPDFFont();

const { Content, Footer } = Layout;

const App = () => {
  const appData = useAppData();
  const {
    token,
    login,
    logout,
    userId,
    username,
    parentMenuPermissions,
    menuPermissions,
    branchPermissions,
  } = useAuth();

  let routes;
  if (token) {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/debts" exact>
          <DebtListByBill />
        </Route>
        <Route path="/debts/member" exact>
          <DebtListByMember />
        </Route>
        {/* <Route path="/debts/:memberId">
          <DebtDetail />
        </Route> */}
        <Route path="/members/t/:memberType/print" exact>
          <PrintListMembers />
        </Route>
        <Route path="/members/t/1" exact>
          <MemberList />
        </Route>
        <Route path="/members/t/1/new" exact>
          <MemberDetail />
        </Route>
        <Route path="/members/t/1/:memberId">
          <MemberDetail />
        </Route>
        <Route path="/members/t/2" exact>
          <MemberTonyList />
        </Route>
        <Route path="/members/t/2/new" exact>
          <MemberTonyDetail />
        </Route>
        <Route path="/members/t/2/:memberId">
          <MemberTonyDetail />
        </Route>
        <Route path="/members/dividend">
          <MemberDividend />
        </Route>
        <Route path="/reports/t/1" exact>
          <Report001 />
        </Route>
        <Route path="/reports/t/2" exact>
          <Report002 />
        </Route>
        <Route path="/reports/t/3" exact>
          <Report003 />
        </Route>
        <Route path="/reports/t/4/m/:memberType" exact>
          <Report004List />
        </Route>
        <Route
          path="/reports/t/4/m/:memberType/:memberId/:startDate/:endDate"
          exact
        >
          <Report004Detail />
        </Route>
        <Route path="/reports/t/5" exact>
          <Report005 />
        </Route>
        <Route path="/reports/t/6" exact>
          <Report006 />
        </Route>
        <Route path="/reports/t/7" exact>
          <Report007 />
        </Route>
        <Route path="/reports/t/8" exact>
          <Report008 />
        </Route>
        <Route path="/bills" exact>
          <BillList />
        </Route>
        <Route path="/bills/offline" exact>
          <OfflineBillList />
        </Route>
        <Route path="/bills/new" exact>
          <BillDetail />
        </Route>
        <Route path="/bills/:billId">
          <BillDetail />
        </Route>
        <Route path="/users" exact>
          <Users />
        </Route>
        <Route path="/users/new" exact>
          <NewUser />
        </Route>
        <Route path="/users/:userId">
          <EditUser />
        </Route>
        <Route path="/settings" exact>
          <Settings />
        </Route>
        <Route path="/settings/branch/new" exact>
          <ViewBranch />
        </Route>
        <Route path="/settings/branch/:branchId">
          <ViewBranch />
        </Route>
        <Route path="/settings/name_title/new" exact>
          <ViewNameTitle />
        </Route>
        <Route path="/settings/name_title/:nameTitleId">
          <ViewNameTitle />
        </Route>
        <Route path="/settings/bank/new" exact>
          <ViewBank />
        </Route>
        <Route path="/settings/bank/:bankId">
          <ViewBank />
        </Route>
        <Route path="/settings/product_cat/new" exact>
          <ViewProductCat />
        </Route>
        <Route path="/settings/product_cat/:productCatId">
          <ViewProductCat />
        </Route>
        <Route path="/settings/equipment/new" exact>
          <ViewEquipment />
        </Route>
        <Route path="/settings/equipment/:equipmentId">
          <ViewEquipment />
        </Route>
        <Route path="/settings/:tabKey">
          <Settings />
        </Route>
        <Route path="/change_password">
          <ChangePassword />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Redirect to="/login" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        username: username,
        parentMenuPermissions: parentMenuPermissions,
        menuPermissions: menuPermissions,
        branchPermissions: branchPermissions,
        login: login,
        logout: logout,
      }}
    >
      <AppDataContext.Provider value={{ ...appData }}>
        <ConfigProvider locale={thTH}>
          <Router>
            <Layout style={{ minHeight: "100vh" }}>
              <MainHeader />
              <Suspense fallback={<div style={{ padding: "24px", fontSize: "20px" }}>Loading...</div>}>
                <Content className="main-content">{routes}</Content>
              </Suspense>
              <Footer>
                © {moment().year()} Tony Rubber v {APP_VERSION}
              </Footer>
            </Layout>
          </Router>
        </ConfigProvider>
      </AppDataContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
