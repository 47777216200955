import { useCallback, useEffect, useState } from "react";

let logoutTimer;

export const useAuth = () => {
  const localStorageKey = "FMuGQD8zRxHG9rU5";

  const storedData = JSON.parse(localStorage.getItem(localStorageKey) || '{}');

  const [token, setToken] = useState(storedData.token);
  const [tokenExpirationDate, setTokenExpirationDate] = useState(new Date(storedData.expiration));
  const [userId, setUserId] = useState(storedData.userId);
  const [username, setUsername] = useState(storedData.username);
  const [parentMenuPermissions, setParentMenuPermissions] = useState(storedData.parentMenuPermissions || []);
  const [menuPermissions, setMenuPermissions] = useState(storedData.menuPermissions || []);
  const [branchPermissions, setBranchPermissions] = useState(storedData.branchPermissions || []);

  const login = useCallback(
    (
      uid,
      username,
      token,
      parentMenuPermissions,
      menuPermissions,
      branchPermissions,
      expirationDate
    ) => {
      setToken(token);
      setUserId(uid);
      setUsername(username);
      setParentMenuPermissions(parentMenuPermissions);
      setMenuPermissions(menuPermissions);
      setBranchPermissions(branchPermissions);

      const tokenExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 240); // 240 minutes
      setTokenExpirationDate(tokenExpirationDate);
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          userId: uid,
          username: username,
          token: token,
          parentMenuPermissions: parentMenuPermissions,
          menuPermissions: menuPermissions,
          branchPermissions: branchPermissions,
          expiration: tokenExpirationDate.toISOString(),
        })
      );
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setUsername(null);
    setParentMenuPermissions([]);
    setMenuPermissions([]);
    setBranchPermissions([]);

    localStorage.removeItem(localStorageKey);
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem(localStorageKey));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.username,
        storedData.token,
        storedData.parentMenuPermissions,
        storedData.menuPermissions,
        storedData.branchPermissions,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  return {
    token,
    login,
    logout,
    userId,
    username,
    parentMenuPermissions,
    menuPermissions,
    branchPermissions,
  };
};
