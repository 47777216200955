import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../Config";
import { thai_province } from "../utils/thai_province";
import { numberSorter, toValueTextObj } from "../utils/utils";

export const useAppData = () => {
  const [reloadMember, setReloadMember] = useState(true);
  const [reloadProductCat, setReloadProductCat] = useState(true);
  const [reloadBranch, setReloadBranch] = useState(true);
  const [reloadNameTitle, setReloadNameTitle] = useState(true);
  const [reloadBank, setReloadBank] = useState(true);
  const [reloadEquipment, setReloadEquipment] = useState(true);
  const [reloadSetting, setReloadSetting] = useState(true);

  const [branch, setBranch] = useState([]);
  const [nameTitle, setNameTitle] = useState([]);
  const [bank, setBank] = useState();
  const [productCat, setProductCat] = useState([]);
  const [member, setMember] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [thaiProvince, setThaiProvince] = useState([]);
  const [sharePrice, setSharePrice] = useState(1000);
  const [years, setYears] = useState([]);

  const [setting, setSetting] = useState({
    min_weight: 0,
    max_weight: 650,
    default_cart_weight: 60,
    bottle_price: 1,
  });

  const menu = [
    { menu_id: "main", menu_name: "หน้าหลัก" },
    { menu_id: "debts", menu_name: "ระบบลูกหนี้" },
    { menu_id: "reports", menu_name: "รายงานสรุป" },
    { menu_id: "members", menu_name: "ระบบสมาชิก" },
    { menu_id: "bills", menu_name: "แก้ไขบิล" },
    { menu_id: "users", menu_name: "พนักงาน" },
    { menu_id: "settings", menu_name: "ตั้งค่า" },
  ].map((d) => toValueTextObj(d, "menu_id", "menu_name"));

  useEffect(() => {
    setThaiProvince(thai_province);
  }, []);

  useEffect(() => {
    const newYears = [];
    for (let year = 2017; year <= 2042; year++) {
      newYears.push(year);
    }
    setYears(newYears);
  }, []);

  useEffect(() => {
    if (reloadSetting) {
      setReloadSetting(false);

      axios.get(`${API_URL}/api/settings/get.php`).then((response) => {
        const results = response.data.data;
        const newSetting = {};
        for (const result of results["settings"]) {
          const key = result["setting_key"];
          const value = result["setting_value"];
          newSetting[key] = value;
          if (key === "share_price") {
            setSharePrice(parseInt(value));
          }
        }
        setSetting({ ...newSetting });
      });
    }
  }, [reloadSetting]);

  useEffect(() => {
    if (reloadEquipment) {
      setReloadEquipment(false);

      axios
        .post(`${API_URL}/api/master/list_equipment.php`)
        .then((response) => {
          const data = response.data.data;
          const equipment = data["equipment"];
          const equipmentPrice = data["equipment_price"];
          const results = equipment.map((data) => {
            const equipmentPriceFiltered = equipmentPrice.filter(
              (v) => v["equipment_id"] === data["equipment_id"]
            );
            return {
              ...data,
              equipment_text: `${data["equipment_no"]} - ${data["equipment_name"]}`,
              priceByAmount: equipmentPriceFiltered,
            };
          });
          results.sort((a, b) => numberSorter(a, b, "equipment_no"));
          setEquipment(results);
        });
    }
  }, [reloadEquipment]);

  useEffect(() => {
    if (reloadBranch) {
      setReloadBranch(false);

      axios.post(`${API_URL}/api/master/list_branch.php`).then((response) => {
        const results = response.data.data.map((data) => ({
          ...toValueTextObj(data, "branch_id", "branch_name"),
          branch_text: `${data["branch_no"]} - ${data["branch_name"]}`,
        }));

        results.sort((a, b) => numberSorter(a, b, "branch_no"));
        setBranch(results);
      });
    }
  }, [reloadBranch]);

  useEffect(() => {
    if (reloadNameTitle) {
      setReloadNameTitle(false);

      axios
        .post(`${API_URL}/api/master/list_name_title.php`)
        .then((response) => {
          const results = response.data.data.map((data) => ({
            ...toValueTextObj(data, "name_title", "name_title"),
          }));

          results.sort((a, b) => numberSorter(a, b, "name_title_no"));
          setNameTitle([{ value: "0", text: "ไม่ระบุ" }, ...results]);
        });
    }
  }, [reloadNameTitle]);

  useEffect(() => {
    if (reloadBank) {
      setReloadBank(false);

      axios.post(`${API_URL}/api/master/list_bank.php`).then((response) => {
        const results = response.data.data.map((data) => ({
          ...toValueTextObj(data, "bank_id", "bank_name"),
        }));

        results.sort((a, b) => numberSorter(a, b, "bank_no"));
        setBank([{ value: "0", text: "ไม่ระบุ" }, ...results]);
      });
    }
  }, [reloadBank]);

  useEffect(() => {
    if (reloadProductCat) {
      setReloadProductCat(false);

      axios
        .post(`${API_URL}/api/master/list_product_cat.php`)
        .then((response) => {
          const results = response.data.data.map((data) => ({
            ...data,
            product_cat_text: `${data["product_cat_no"]} - ${data["product_cat_name"]}`,
          }));

          results.sort((a, b) => numberSorter(a, b, "product_cat_no"));
          setProductCat(results);
        });
    }
  }, [reloadProductCat]);

  useEffect(() => {
    if (reloadMember) {
      setReloadMember(false);

      axios.post(`${API_URL}/api/master/list_member.php`).then((response) => {
        setMember(
          response.data.data.map((data) => {
            const nameTitle =
              data["name_title"] === "0" ? "" : data["name_title"];
            return {
              ...data,
              member_text: `${data["member_no"]} - ${nameTitle} ${data["firstname"]} ${data["lastname"]}`,
            };
          })
        );
      });
    }
  }, [reloadMember]);

  return {
    branch,
    productCat,
    equipment,
    member,
    sharePrice,
    thaiProvince,
    menu,
    nameTitle,
    bank,
    years,
    setting,
    setSetting,
    setReloadMember,
    setReloadNameTitle,
    setReloadBank,
    setReloadProductCat,
    setReloadBranch,
    setReloadEquipment,
    setReloadSetting,
  };
};
