import { Col, Dropdown, Icon, Layout, Menu, Row, Typography } from "antd";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "../../context/auth-context";
import { containerSize } from "../../utils/size";
import { hasAnyPermissions } from "../../../shared/utils/utils";

import "./MainHeader.css";

const { SubMenu } = Menu;
const { Header } = Layout;
const { Title } = Typography;

const styles = {
  header: {
    padding: "0 24px",
    backgroundColor: "#fff",
    // borderBottom: '1pt solid #e8e8e8',
    // position: 'fixed', zIndex: 1, width: '100%'
  },
  appName: {
    display: "inline",
    marginRight: 8,
  },
  appNameLink: {
    // color: 'black'
  },
  rightHeader: {
    float: "right",
  },
  username: {
    cursor: "pointer",
  },
};

const menus = [
  {
    typeOfIcon: "database",
    key: "bills",
    name: "รายการบิล",
    path: "/bills",
    parentMenuPermissions: ["bill"],
  },
  {
    typeOfIcon: "solution",
    key: "debts",
    name: "ระบบลูกหนี้",
    path: "/debts",
    parentMenuPermissions: ["debt"],
    submenu: [
      {
        key: "debt_member",
        name: "รายการลูกหนี้",
        path: "/debts/member",
        menuPermissions: [
          "debt-view",
          "debt-increase",
          "debt-decrease",
          "debt-edit",
          "debt-delete",
          "debt-print-info",
          "debt-print-bill",
        ],
      },
      {
        key: "debt_list",
        name: "รายการบิล (หนี้)",
        path: "/debts",
        menuPermissions: ["debt-view"],
      },
    ],
  },
  {
    typeOfIcon: "reconciliation",
    key: "reports",
    name: "รายงานสรุป",
    path: "/reports",
    parentMenuPermissions: ["report"],
    submenu: [
      {
        key: "reports_1",
        name: "รายงานสรุปยอดซื้อตามลำดับบิล",
        path: "/reports/t/1",
        menuPermissions: ["report-1", "report-1-view", "report-1-print"],
      },
      {
        key: "reports_2",
        name: "รายงานสรุปยอดซื้อตามประเภทยาง",
        path: "/reports/t/2",
        menuPermissions: ["report-2", "report-2-view", "report-2-print"],
      },
      {
        key: "reports_3",
        name: "รายงานสรุปยอดซื้อรวมตามวันที่",
        path: "/reports/t/3",
        menuPermissions: ["report-3", "report-3-view", "report-3-print"],
      },
      {
        key: "reports_4",
        name: "รายงานสรุปยอดซื้อของสมาชิกกลุ่มวิสาหกิจชุมชน",
        path: "/reports/t/4/m/1",
        menuPermissions: [
          "report-4",
          "report-4-view",
          "report-4-print",
          "report-4-print-info",
        ],
      },
      {
        key: "reports_5",
        name: "รายงานสรุปยอดซื้อของสมาชิกโทนี่ยางพารา",
        path: "/reports/t/4/m/2",
        menuPermissions: [
          "report-5",
          "report-5-view",
          "report-5-print",
          "report-5-print-info",
        ],
      },
      {
        key: "reports_6",
        name: "รายงานสรุปยอดซื้อประจำวัน",
        path: "/reports/t/5",
        menuPermissions: [
          "report-6",
          "report-6-view",
          "report-6-save",
          "report-6-print",
        ],
      },
      {
        key: "reports_7",
        name: "รายงานสรุปยอดขายอุปกรณ์",
        path: "/reports/t/6",
        menuPermissions: ["report-7", "report-7-view", "report-7-print"],
      },
      {
        key: "reports_8",
        name: "รายงานสรุปยอดขายน้ำกรดประจำวัน",
        path: "/reports/t/7",
        menuPermissions: ["report-8", "report-8-view", "report-8-save"],
      },
      {
        key: "reports_9",
        name: "รายงานสรุปยอดคืนขวด",
        path: "/reports/t/8",
        menuPermissions: ["report-9", "report-9-view", "report-9-print"],
      },
    ],
  },
  {
    typeOfIcon: "idcard",
    key: "members",
    name: "ระบบสมาชิก",
    parentMenuPermissions: ["member1", "member2"],
    submenu: [
      {
        typeOfIcon: "idcard",
        key: "members_1",
        name: "สมาชิกกลุ่มวิสาหกิจชุมชน",
        path: "/members/t/1",
        menuPermissions: [
          "member1-view",
          "member1-create",
          "member1-edit",
          "member1-delete",
        ],
      },
      {
        typeOfIcon: "team",
        key: "members_2",
        name: "สมาชิกโทนี่ยางพารา",
        path: "/members/t/2",
        menuPermissions: [
          "member2-view",
          "member2-create",
          "member2-edit",
          "member2-delete",
        ],
      },
      {
        typeOfIcon: "dollar",
        key: "members_3",
        name: "เงินปันผลประจำปี",
        path: "/members/dividend",
        menuPermissions: ["member3-view", "member3-upload"],
      },
      {
        typeOfIcon: "printer",
        key: "members_4",
        name: "รายการพิมพ์",
        path: "/members/t/1/print",
        menuPermissions: [
          "member1-print",
          "report-4-print-info",
          "report-4-print-dividend",
        ],
      },
    ],
  },
  {
    typeOfIcon: "user",
    key: "users",
    name: "พนักงาน",
    path: "/users",
    parentMenuPermissions: ["user"],
  },
  {
    typeOfIcon: "setting",
    key: "settings",
    name: "ตั้งค่า",
    path: "/settings/general",
    parentMenuPermissions: ["setting"],
  },
];

const MainHeader = (props) => {
  const auth = useContext(AuthContext);

  const menu = (
    <Menu>
      <Menu.Item key="change_password">
        <Link to="/change_password">เปลี่ยนรหัสผ่าน</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={auth.logout}>
        ออกจากระบบ
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {auth.isLoggedIn && (
        <Header style={styles.header}>
          <Row>
            <Col {...containerSize} style={{ display: "flex" }}>
              <div>
                <Title
                  className="app-name-title"
                  level={3}
                  style={styles.appName}
                >
                  <Link to="/">Tony Rubber</Link>
                </Title>
              </div>
              <div style={{ flex: "1 1 0%", overflow: "hidden" }}>
                <Menu
                  mode="horizontal"
                  defaultSelectedKeys={["2"]}
                  style={{ lineHeight: "62px" }}
                >
                  {menus
                    .filter((menu) =>
                      hasAnyPermissions(
                        menu.parentMenuPermissions,
                        auth.parentMenuPermissions
                      )
                    )
                    .map((menu) => {
                      if (!menu.submenu) {
                        return (
                          <Menu.Item key={menu.key}>
                            <Link to={menu.path}>
                              {menu.icon}
                              <Icon type={menu.typeOfIcon} />
                              <span>{menu.name}</span>
                            </Link>
                          </Menu.Item>
                        );
                      } else {
                        return (
                          <SubMenu
                            key={menu.key}
                            title={
                              <span className="submenu-title-wrapper">
                                <Icon type={menu.typeOfIcon} />
                                {menu.name}
                              </span>
                            }
                          >
                            {menu.submenu
                              .filter((sub) =>
                                hasAnyPermissions(
                                  sub.menuPermissions,
                                  auth.menuPermissions
                                )
                              )
                              .map((sub) => (
                                <Menu.Item key={sub.key}>
                                  <Link to={sub.path}>
                                    {sub.typeOfIcon && (
                                      <Icon type={sub.typeOfIcon} />
                                    )}
                                    <span>{sub.name}</span>
                                  </Link>
                                </Menu.Item>
                              ))}
                          </SubMenu>
                        );
                      }
                    })}
                </Menu>
              </div>
              <div className="user" style={styles.rightHeader}>
                {auth.isLoggedIn && (
                  <>
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <span
                        className="ant-dropdown-link link"
                        style={styles.username}
                        onClick={(e) => e.preventDefault()}
                      >
                        {auth.username} <Icon type="down" />
                      </span>
                    </Dropdown>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Header>
      )}
    </>
  );
};

export default MainHeader;
