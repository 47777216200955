import moment from "moment";
import { localDataKey } from "./constant";

export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "HH:mm:ss";

export const renderTexts = (ids, datas) => {
  const texts = ids.map((id) => {
    const data = datas.find((d) => d.value === id);
    if (data) {
      return data.text;
    } else {
      return id;
    }
  });
  return texts.join(", ");
};

export const toValueTextObj = (d, valFieldName, textFieldName) => {
  return { ...d, value: d[valFieldName], text: d[textFieldName] };
};

export const getBuddhistDateByText = (text, showDate = true) => {
  if (text) {
    // input ex. 2020-03-10 09:45:42
    // input ex. 10/03/2020 09:45:42
    const date = parse(text);

    const year = date.year();
    const buddhistYear = year + 543;

    return `${format(date, "DD/MM")}/${buddhistYear} ${
      showDate ? format(date, "HH:mm:ss") : ""
    }`;
  } else {
    return "";
  }
};

export const format = (date, inputFormat = DATE_TIME_FORMAT) => {
  if (date === null || date === undefined) {
    return null;
  } else {
    return date.format(inputFormat);
  }
};

export const parse = (text, inputFormat = DATE_TIME_FORMAT) => {
  if (text === null || text === undefined || text === "") {
    return null;
  } else {
    return moment(text, inputFormat);
  }
};

export const getAge = (dateOfBirth, inputFormat = DATE_TIME_FORMAT) => {
  if (!dateOfBirth || dateOfBirth === "") {
    return "";
  } else if (typeof dateOfBirth === "string") {
    dateOfBirth = parse(dateOfBirth, inputFormat);
  }
  return moment().diff(dateOfBirth, "years");
};

export const changeFormat = (
  text,
  inputFormat = DATE_TIME_FORMAT,
  outputFormat = DATE_FORMAT
) => {
  const date = parse(text, inputFormat);
  return format(date, outputFormat);
};

// Will Deprecated to change format
// export const formatDate = (text, format = DATE_FORMAT) => {
//   console.log('text', text)
//   if (!text) return '';
//   const thaiDate = text; //renderThaiDate(text);
//   const m = moment(thaiDate, DATE_TIME_FORMAT);
//   const mText = m.format(format);
//   console.log(mText);
//   return mText;
// };

export const formatMoney = (
  amount,
  decimalCount = 0,
  decimal = ".",
  thousands = ","
) => {
  if (amount === null) return "";

  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = amount < 0 ? "-" : "";

  let i = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
  ).toString();
  let j = i.length > 3 ? i.length % 3 : 0;

  return (
    negativeSign +
    (j ? i.substr(0, j) + thousands : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : "")
  );
};

export const getAddress = (
  [provinceCode, districtCode, subDistrictCode],
  addressData
) => {
  const filterProvince = addressData.filter(
    (obj) => obj.value === provinceCode
  );
  const provinceData = [];

  if (filterProvince.length > 0 && filterProvince[0]) {
    provinceData.push(filterProvince[0]);

    const filterDistrict = filterProvince[0].children.filter(
      (obj) => obj.value === districtCode
    );

    if (filterDistrict.length > 0 && filterDistrict[0]) {
      provinceData.push(filterDistrict[0]);

      const filterSubDistrict = filterDistrict[0].children.filter(
        (obj) => obj.value === subDistrictCode
      );
      if (filterSubDistrict.length > 0 && filterSubDistrict[0]) {
        provinceData.push(filterSubDistrict[0]);
      }
    }
  }

  return provinceData;
};

export const getAddressText = (province, masterThaiProvince) => {
  const provinceData = getAddress(province, masterThaiProvince);
  const provinceLabel =
    provinceData.length > 0 ? " " + provinceData[0]["label"] : "";
  const districtLabel =
    provinceData.length > 1 ? " " + provinceData[1]["label"] : "";
  const subDistrinctLabel =
    provinceData.length > 2 ? " " + provinceData[2]["label"] : "";
  const zipCodeLabel =
    provinceData.length > 2 ? " " + provinceData[2]["code"] : "";
  const provinceText =
    provinceData.length > 0
      ? " " + subDistrinctLabel + districtLabel + provinceLabel + zipCodeLabel
      : "";
  return provinceText;
};

export const sorter = function (a, b, attr) {
  if (a[attr] < b[attr]) {
    return -1;
  }
  if (a[attr] > b[attr]) {
    return 1;
  }
  return 0;
};

export const numberSorter = function (a, b, attr) {
  if (parseInt(a[attr]) < parseInt(b[attr])) {
    return -1;
  }
  if (parseInt(a[attr]) > parseInt(b[attr])) {
    return 1;
  }
  return 0;
};

export const addCommas = (num) =>
  (num || "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const removeNonNumeric = (num) =>
  (num || "").toString().replace(/[^0-9-]/g, "");

export const sum = function (array, key) {
  return array.reduce((a, b) => {
    const num = ((b[key] || 0) + "").replace(/,/g, "");
    return a + Number(num);
  }, 0);
};

export const getParentMenuKey = (menuPermissions) => {
  const parentMenuPermissions = [];
  for (const checkedKey of menuPermissions) {
    const temp = checkedKey.split("-");
    if (temp && temp.length > 0) {
      const parentKey = temp[0];
      if (parentMenuPermissions.indexOf(parentKey) < 0) {
        parentMenuPermissions.push(parentKey);
      }
    }
  }

  return parentMenuPermissions;
};

export const hasAnyPermissions = (
  requiredPermissions = [],
  grantedPermissions = []
) => {
  for (const requiredPermission of requiredPermissions) {
    const foundRequiredPermissionIndex =
      grantedPermissions.indexOf(requiredPermission);
    if (foundRequiredPermissionIndex >= 0) {
      return true;
    }
  }
  return false;
};

export const getLocalData = (key, defaultValue) => {
  const data = localStorage.getItem(localDataKey);
  const dataObject = JSON.parse(data);

  if (dataObject && dataObject.hasOwnProperty(key) && dataObject[key]) {
    return dataObject[key];
  } else {
    return defaultValue;
  }
};

export const setLocalData = (key, value) => {
  const data = localStorage.getItem(localDataKey);
  const dataObject = JSON.parse(data) || {};

  dataObject[key] = value;
  localStorage.setItem(localDataKey, JSON.stringify(dataObject));
};
