import { Font } from "@react-pdf/renderer";

const RegisterPDFFont = () => {
  Font.register({
    family: "Sarabun",
    src: "/Sarabun-Regular.ttf",
  });

  Font.register({
    family: "SarabunBold",
    src: "/Sarabun-Bold.ttf",
  });
};

export default RegisterPDFFont;
